



















































































import {Vue, Component, Prop} from 'vue-property-decorator';
import moment, {Moment} from "moment-timezone";
import PortalTime from "@/components/common/PortalTime.vue";
import TimeSheetDay from "@/components/payroll/timesheet/TimeSheetDay.vue";
import {TimeSheetFilter, TimeSheetPayDay, TimeSheetPayPeriod} from "@/dto/payroll/timesheet/TimesheetPayloads";
import {Md5} from "ts-md5";
import TimeSheetDetails from "@/components/payroll/timesheet/TimeSheetDetails.vue";
import Application from "@/state/Application";
import TimesheetService from "@/services/payroll/TimesheetService";
import {processError} from "@/utils/ComponentUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";

@Component({
	methods: {
		hashCode(obj: any): string {
			return Md5.hashStr(JSON.stringify(obj))
		},
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	},
	components: {PortalDate, TimeSheetDetails, TimeSheetDay, PortalTime}
})
export default class TimeSheetPeriod extends Vue {

	private payPeriodInternal: TimeSheetPayPeriod | null = null

	private selectedDayInternal: Date | null = null

	@Prop()
	private period!: TimeSheetPayPeriod

	@Prop({default: null, required: false})
	private project!: number | null

	@Prop()
	private timeFormat!: string

	private internalDay = moment().format('YYYY-MM-DD')

	private filter = new TimeSheetFilter(
		{
			employmentsId: [this.payPeriod.employment.id!],
			projectId: this.project
		}
	)

	private async loadPeriod(day: string = this.internalDay): Promise<void> {
		Application.startLoading()
		this.filter.startDate = moment(day).set({weekday: 0}).format("YYYY-MM-DD")
		this.filter.endDate = moment(day).set({weekday: 6}).format("YYYY-MM-DD")
		return TimesheetService.getPayPeriod(this.filter).then(
			res => {
				this.payPeriodInternal = res.data[0];
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private selectDay(day: TimeSheetPayDay) {
		this.selectedDayInternal = day.date
	}

	private previous() {
		this.internalDay = moment(this.internalDay).add({week: -1}).format("YYYY-MM-DD")
		this.selectedDayInternal = null
		this.loadPeriod()
	}

	private next() {
		this.internalDay = moment(this.internalDay).add({week: 1}).format("YYYY-MM-DD")
		this.selectedDayInternal = null
		this.loadPeriod()
	}

	get selectedDay(): TimeSheetPayDay | null {
		const key = this.selectedDayInternal ? this.selectedDayInternal : this.internalDay
		return this.payPeriod.payDates.find(it => moment(it.date).dayOfYear() == moment(key).dayOfYear()) ?? null
	}

	get resolvePreviousPeriod(): string {
		const previousFirstDay = moment(this.internalDay).add({week: -1}).set({weekday: 0});
		const previousLastDay = moment(this.internalDay).add({week: -1}).set({weekday: 6});
		return `${previousFirstDay.format("MM.DD")} - ${previousLastDay.format("MM.DD")}`
	}

	get resolveCurrentPeriod(): string {
		const currentFirstDay = moment(this.internalDay).set({weekday: 0});
		const currentLastDay = moment(this.internalDay).set({weekday: 6});
		return `${currentFirstDay.format("MM.DD")} - ${currentLastDay.format("MM.DD")}`
	}

	get resolveNextPeriod(): string {
		const nextFirstDay = moment(this.internalDay).add({week: 1}).set({weekday: 0});
		const nextLastDay = moment(this.internalDay).add({week: 1}).set({weekday: 6});
		return `${nextFirstDay.format("MM.DD")} - ${nextLastDay.format("MM.DD")}`
	}

	get payPeriod(): TimeSheetPayPeriod  {
		return this.payPeriodInternal ? this.payPeriodInternal : this.period
	}

	mobileDay(day: string = this.internalDay): string {
		return `${moment().localeData().months()[moment(day).month()]} ${moment(day).date()}, ${moment(day).year()}`
	}

	mobilePeriod(day: string = this.internalDay): string {
		return `${this.mobileWeek(moment(day).set({weekday: 0}).format("YYYY-MM-DD"))} - ${this.mobileWeek(moment(day).set({weekday: 6}).format("YYYY-MM-DD"))}`
	}

	mobileWeek(day: string = this.internalDay): string {
		return `${moment().localeData().monthsShort()[moment(day).month()]} ${moment(day).date()}, ${moment(day).year()}`
	}

	private nextDay() {
		const nextDay = moment(this.internalDay).add({day: 1})
		if (nextDay.week() == moment(this.internalDay).week()) {
			this.internalDay = nextDay.format("YYYY-MM-DD");
			return
		} else {
			const res = moment(this.internalDay).add({week: 1}).set({weekday: 0}).format("YYYY-MM-DD")
			this.internalDay = res
			this.loadPeriod(res).then(
				() => {
					this.internalDay = res
				}
			)
		}
	}

	private previousDay() {
		const nextDay = moment(this.internalDay).add({day: -1})
		if (nextDay.week() == moment(this.internalDay).week()) {
			this.internalDay = nextDay.format("YYYY-MM-DD");
			return
		} else {
			const res = moment(this.internalDay).add({week: -1}).set({weekday: 6}).format("YYYY-MM-DD")
			this.internalDay = res
			console.log(res)
			this.loadPeriod(res).then(
				() => {
					this.internalDay = res
				}
			)
		}
	}

}
