







































































import {Component, Prop} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "@/utils/StringUtils";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {SickLeave, SickLeaveFilter} from "@/components/payroll/sickLeave/SickLeave";
import PortalDate from "@/components/common/PortalDate.vue";
import SickLeavePolicyCreationModal from "@/components/payroll/sickLeave/SickLeaveCreationModal.vue";
import SickLeaveOptionsModal from "@/components/payroll/sickLeave/SickLeaveOptionsModal.vue";
import SickLeaveBalanceEditModal from "@/components/payroll/sickLeave/SickLeaveBalanceEditModal.vue";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";
import {WorkspaceType} from "@/dto/auth/Workspace";
import EmploymentSLRList from "@/components/payroll/sickLeave/slr/EmploymentSLRList.vue";
import SickLeaveRequestCreationModal from "@/components/payroll/sickLeave/slr/SickLeaveRequestCreationModal.vue";
import SickLeaveBalanceDetails from "@/components/payroll/sickLeave/SickLeaveBalanceDetails.vue";

@Component({
	computed: {
		WorkspaceType() {
			return WorkspaceType
		}
	},
	components: {SickLeaveBalanceDetails, EmploymentSLRList, PortalDate, PaginationComponent},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	}
})
export default class EmploymentSickLeaveList extends EmploymentVue<IEmployment> {

	private sickLeaves: Array<SickLeave> = [];
	private totalPages = 0
	private slrCount = 0;

	@Prop()
	employment!: IEmployment

	private filter = new SickLeaveFilter(
		{
			employmentsId: [this.employment.id!]
		}
	)

	private loadSickLeaves() {
		Application.startLoading()
		SickLeaveService.getPageByFilter(this.filter).then(
			res => {
				this.sickLeaves = res.data.data
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadSickLeaves()
	}

	private showCreationWindow() {
		this.$modal.show(
			SickLeavePolicyCreationModal,
			{
				employment: this.employment,
				callback: () => this.loadSickLeaves()
			}
		)
	}

	private showOptions(sickLeave: SickLeave) {
		this.$modal.show(
			SickLeaveOptionsModal,
			{
				sickLeave: sickLeave,
				callback: () => this.loadSickLeaves()
			}
		)
	}

	private showCarryoverModal() {
		this.$modal.show(
			SickLeaveBalanceEditModal,
			{
				employment: this.employment,
				callback: () => this.loadSickLeaves()
			}
		)
	}

	private changePage(page: number){
		this.filter.page = page - 1;
		this.loadSickLeaves();
	}

	private reset() {
		this.filter = new SickLeaveFilter(
			{
				employerId: this.$wss.getCurrent.id,
				startDate: moment().format("YYYY-MM-DD")
			}
		)
		this.loadSickLeaves()
	}

	private showCreationSLRWindow() {
		this.$modal.show(
			SickLeaveRequestCreationModal,
			{
				employment: this.employment,
				callback: () => this.slrCount += 1
			}
		)
	}

}
