import {ContractType, EmploymentType} from "@/constants/EmploymentType";
import CompanyDTO from "@/dto/company/CompanyDTO";
import {PolicySignature, PTOPolicy} from "@/dto/payroll/policies/PTOPolicyDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export class CreateSickLeavePolicyPayload {
    companyId: number | null = null;

    notPayInterval: number | null = null;
    accumulationRate: number | null = 30;
    payout = false;
    payInterval: number | null = null;
    carryover: number | null = null;

    applicableState: string | null = "NY"
    title = `Sick Leave Policy (${this.applicableState})`;
    applicableFor: ContractType | null = null;
    employmentType: EmploymentType | null = null;

    constructor(init?: Partial<CreateSickLeavePolicyPayload>) {
        Object.assign(this, init)
    }
}

export class UpdateSickLeavePolicyPayload {
    policyId: number | null = null;

    notPayInterval: number | null = null;
    carryover: number | null = null;

    payout = false;
    payInterval: number | null = null;

    applicableState: string | null = "NY"
    title = `Sick Leave Policy (${this.applicableState})`;
    applicableFor: ContractType | null = null;

    constructor(init?: Partial<UpdateSickLeavePolicyPayload>) {
        Object.assign(this, init)
    }
}

export class SickLeavePolicy {
    id!: number;
    company!: CompanyDTO;
    payout!: boolean;
    intervalHours!: number;
    notPayIntervalHours!: number;
    notPayIntervalDays!: number;
    intervalDays!: number;
    carryoverHours!: number;
    carryoverDays!: number;
    title!: string;
    accumulationRate!: number;
    applicableFor: ContractType | null = null;
    applicableState: string | null = null;
    isActive!: boolean
    createDate!: Date;
    signatures: Array<PolicySignature> = []
    publicId?: string;
    approvedPolicy!: FileMetaDTO
}

export class SLPSignature {
    id!: number
    employment!: IEmployment;
    policy!: SickLeavePolicy;
    document?: FileMetaDTO;
    publicId?: string
}

export class SickLeavePolicyFilter {
    companyId: number | null = null;
    applicableFor: EmploymentType | null = null;
    applicableState: string | null = null;
    isActive: boolean | null = null;
    signed: boolean | null = null;
    pageNumber = 0;
    pageSize = 20

    constructor(init?: Partial<SickLeavePolicyFilter>) {
        Object.assign(this, init)
    }
}