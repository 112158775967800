































































































































import PortalInput from "@/components/common/PortalInput.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import Portal from "portal-vue/src/components/portal";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {EMPLOYMENT_TYPE_OPTIONS, ContractType, contractTypeOptions, EmploymentType} from "@/constants/EmploymentType";
import {STATE_LIST} from "@/constants/ProfileConstants";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {CreateHAOPolicyPayload, Holiday} from "@/dto/payroll/policies/HAOPolicyDTO";
import {prettyEnum} from "@/utils/StringUtils";
import {Week} from "@/constants/DateTimeConstants";
import PolicyService from "@/services/payroll/PolicyService";
import {Md5} from "ts-md5";

@Component(
	{
		methods: {
			contractTypeOptions: contractTypeOptions,
			STATE_LIST() {
				return STATE_LIST
			}
		},
		computed: {
			ContractType() {
				return ContractType
			},
			SelectOption() {
				return SelectOption
			},
			EMPLOYMENT_TYPE_OPTIONS() {
				return EMPLOYMENT_TYPE_OPTIONS
			}
		},
		components: {PortalRadio, PortalSelect, Portal, PortalCheckbox, PortalInput}
	}
)
export default class PTOCreationModal extends Vue {

	@Prop()
	private callback!: () => void

	private states = STATE_LIST;

	private federalHolidays: Array<Holiday> = []

	private req: CreateHAOPolicyPayload = new CreateHAOPolicyPayload(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	public created() {
		Application.startLoading()
		PolicyService.getFederalHolidays().then(
			res => {
				this.federalHolidays = res.data
				Application.stopLoading()
			}
		)
	}

	private create() {
		ifValid(this, () => {
				Application.startLoading();
				PolicyService.createHAOPolicy(this.req).then(
					() => {
						Application.stopLoading()
						this.$modal.hideAll();
						this.callback()
					},
					err => processError(err, this)
				)
			}
		)
	}

	private get contractType(): Array<SelectOption> {
		const options: Array<SelectOption> = []
		options.push(SelectOption.builder().title("All").value(null).build())
		options.push(...contractTypeOptions())
		return options;
	}

	private get employmentOptions(): SelectOption[] {
		return [
			SelectOption.builder().title("All").value(null).build(),
			SelectOption.builder().title("Employee").value(EmploymentType.STAFF).build(),
			SelectOption.builder().title("Foreign contractor").value(EmploymentType.FOREIGN).build()
		]
	}

	private buildWeekDayOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(prettyEnum(Week.SUNDAY)).value(Week.SUNDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.MONDAY)).value(Week.MONDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.TUESDAY)).value(Week.TUESDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.WEDNESDAY)).value(Week.WEDNESDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.THURSDAY)).value(Week.THURSDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.FRIDAY)).value(Week.FRIDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.SATURDAY)).value(Week.SATURDAY).build(),
		]
	}

	get holidayOptions() {
		return this.federalHolidays.map(
			it => SelectOption.builder()
				.title(`${it.title} (${it.formattedDate})`)
				.value(it)
				.build()
		)
	}
}
