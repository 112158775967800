import {
    CreatePTOPolicyPayload,
    PTOPolicy,
    PTOPolicyFilter, PTOSignature,
    UpdatePTOPolicyPayload
} from "@/dto/payroll/policies/PTOPolicyDTO";
import QueryUtils from "@/utils/QueryUtils";
import axios, {AxiosResponse} from "axios";
import Pageable from "@/dto/Pageable";
import {
    PAYROLL_HAO_POLICY_ENDPOINT,
    PAYROLL_PTO_POLICY_ENDPOINT,
    PAYROLL_SLP_POLICY_ENDPOINT
} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import {
    CreateSickLeavePolicyPayload,
    SickLeavePolicy,
    SickLeavePolicyFilter, SLPSignature, UpdateSickLeavePolicyPayload
} from "@/dto/payroll/policies/SickLeavePolicyDTO";
import {
    CreateHAOPolicyPayload,
    HAOPFilter,
    HAOPolicy,
    HAOPSignature,
    Holiday
} from "@/dto/payroll/policies/HAOPolicyDTO";

class PolicyService {
    getPageOfPTOPolicies(filter: PTOPolicyFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<PTOPolicy>>(`${PAYROLL_PTO_POLICY_ENDPOINT}${query}`, {headers: authHeader()})
    }

    createPTOPolicy(req: CreatePTOPolicyPayload) {
        return axios.post<void>(`${PAYROLL_PTO_POLICY_ENDPOINT}`, req, {headers: authHeader()})
    }

    disablePTO(id: number) {
        return axios.delete<void>(`${PAYROLL_PTO_POLICY_ENDPOINT}/${id}`, {headers: authHeader()})
    }

    editPTOPolicy(req: UpdatePTOPolicyPayload) {
        return axios.put<void>(`${PAYROLL_PTO_POLICY_ENDPOINT}`, req, {headers: authHeader()})
    }

    disablePTOSignature(id: number, employmentId: number) {
        return axios.delete<void>(`${PAYROLL_PTO_POLICY_ENDPOINT}/signature/${id}?employmentId=${employmentId}`, {headers: authHeader()})
    }

    getPageOfSickLeavePolicies(filter: SickLeavePolicyFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<SickLeavePolicy>>(`${PAYROLL_SLP_POLICY_ENDPOINT}${query}`, {headers: authHeader()})
    }

    createSickLeavePolicy(req: CreateSickLeavePolicyPayload) {
        return axios.post<void>(`${PAYROLL_SLP_POLICY_ENDPOINT}`, req, {headers: authHeader()})
    }

    updateSickLeavePolicy(req: UpdateSickLeavePolicyPayload) {
        return axios.put<void>(`${PAYROLL_SLP_POLICY_ENDPOINT}`, req, {headers: authHeader()})
    }

    disableSickLeave(id: number) {
        return axios.delete<void>(`${PAYROLL_SLP_POLICY_ENDPOINT}/${id}`, {headers: authHeader()})
    }

    disableSLPSignature(id: number, employmentId: number) {
        return axios.delete<void>(`${PAYROLL_SLP_POLICY_ENDPOINT}/signature/${id}?employmentId=${employmentId}`, {headers: authHeader()})
    }

    getPTOPolicyByEmploymentId(employmentId: number) {
        return axios.get<PTOSignature>(`${PAYROLL_PTO_POLICY_ENDPOINT}/signature?employmentId=${employmentId}`, {headers: authHeader()})
    }

    getSickLeavePolicyByEmploymentId(employmentId: number) {
        return axios.get<SLPSignature>(`${PAYROLL_SLP_POLICY_ENDPOINT}/signature?employmentId=${employmentId}`, {headers: authHeader()})
    }

    attachSLP(employmentId: number, policyId: number) {
        return axios.post<void>(`${PAYROLL_SLP_POLICY_ENDPOINT}/${policyId}/signature`, {employmentId: employmentId, policyId: policyId}, {headers: authHeader()})
    }

    attachPTO(employmentId: number, policyId: number) {
        return axios.post(`${PAYROLL_PTO_POLICY_ENDPOINT}/${policyId}/signature`, {employmentId: employmentId, policyId: policyId}, {headers: authHeader()})
    }

    getHAOPs(filter: HAOPFilter): Promise<AxiosResponse<Pageable<HAOPolicy>>> {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<HAOPolicy>>(`${PAYROLL_HAO_POLICY_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getFederalHolidays(): Promise<AxiosResponse<Array<Holiday>>> {
        return axios.get<Array<Holiday>>(`${PAYROLL_HAO_POLICY_ENDPOINT}/federal-holidays`, {headers: authHeader()})
    }

    createHAOPolicy(payload: CreateHAOPolicyPayload): Promise<AxiosResponse<void>> {
        return axios.post<void>(`${PAYROLL_HAO_POLICY_ENDPOINT}`, payload, {headers: authHeader()})
    }

    disableHAOPSignature(id: number) {
        return axios.delete<void>(`${PAYROLL_HAO_POLICY_ENDPOINT}/signatures/${id}`, {headers: authHeader()})
    }

    disableHAOP(haopId: number) {
        return axios.delete<void>(`${PAYROLL_HAO_POLICY_ENDPOINT}/${haopId}`, {headers: authHeader()})
    }

    getHAOPByEmploymentId(employmentId: number) {
        return axios.get<HAOPSignature>(`${PAYROLL_HAO_POLICY_ENDPOINT}/signatures?employmentId=${employmentId}`, {headers: authHeader()})
    }

    attachHAOP(employmentId: number, policyId: number) {
        return axios.post<void>(`${PAYROLL_HAO_POLICY_ENDPOINT}/${policyId}/signatures`, {employmentId: employmentId, policyId: policyId}, {headers: authHeader()})
    }
}

export default new PolicyService()