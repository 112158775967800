

















import {Component, Prop, Vue} from 'vue-property-decorator';
import SLRData from "@/components/payroll/sickLeave/slr/SLRData.vue";
import {CompleteSLRPayload, DecisionSLR, SLRStatus} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import SLRDayPayFlagPrepare from "@/components/payroll/sickLeave/slr/SLRDayPayFlagPrepare.vue";
import SickLeaveRequest from "@/components/payroll/sickLeave/slr/SickLeaveRequest.vue";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import {WorkspaceType} from "@/dto/auth/Workspace";

@Component({
	components: {SickLeaveRequest, SLRDayPayFlagPrepare, SLRData}
})
export default class SLRDecision extends Vue {

	@Prop()
	private slr!: DecisionSLR

	private complete() {
		Application.startLoading()
		SickLeaveService.completeSLR(
			new CompleteSLRPayload(
				{
					companyId: this.$wss.getCurrent.type == WorkspaceType.COMPANY ? this.$wss.getCurrent.id : null,
					requestId: this.slr.id,
					markedPeriod: this.slr.days
				}
			)
		).then(
			() => {
				this.slr.status = SLRStatus.COMPLETED
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

}
