















import {Vue, Component, Prop} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import {CloseSLRPayload, SLR} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import Application from "@/state/Application";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {processError} from "@/utils/ComponentUtils";
import moment from "moment";
import Notifications from "@/state/Notifications";

@Component({
	components: {PortalCheckbox, PortalDate, SingleFileHolder, PortalRadio}
})
export default class SickLeaveRequestClosingModal extends Vue {

	@Prop()
	private employment!: IEmployment

	@Prop()
	private slr!: SLR

	@Prop({default: true})
	private hasSupportDocument!: boolean

	@Prop()
	private callback!: () => void

	private req = new CloseSLRPayload(
		{
			requestId: this.slr.id,
			employmentId: this.employment.id,
			endDate: this.resolveEndDate().format("YYYY-MM-DD")
		}
	)

	private resolveEndDate() {
		const yesterday = moment().add({day: -1})
		if (yesterday.isAfter(this.slr.sickLeave.startDate, "date")) {
			return yesterday
		} else {
			return moment()
		}
	}

	private closeSLR() {
		if (moment(this.req.endDate).isBefore(moment(this.slr.sickLeave.startDate))) {
			Notifications.error("End date must be after start date")
		} else {
			Application.startLoading()
			SickLeaveService.closeRequest(this.req).then(
				() => {
					Application.stopLoading()
					this.$modal.hideAll()
					this.callback()
				},
				err => processError(err, this)
			)
		}
	}

}
