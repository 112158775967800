


























import {Component, Prop, Vue} from 'vue-property-decorator';
import {ExistPolicies} from "@/components/payroll/timesheet/IndividualTimesheet.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import VacationRequestCreationModal from "@/components/payroll/vacation/VacationRequestCreationModal.vue";
import SickLeaveNoticeModalCreation from "@/components/payroll/sickLeave/slr/SickLeaveNoticeModalCreation.vue";
import PolicyService from "@/services/payroll/PolicyService";
import {AllVCRFilter, VCR, VCRStatus} from "@/dto/payroll/VacationDTO";
import VacationService from "@/services/payroll/VacationService";
import {prettyEnum} from "../../../utils/StringUtils";
import RouteNames from "@/router/RouteNames";
import moment, {Moment} from "moment-timezone";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {SLR, SLRFilter, SLRStatus} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import SickLeaveRequestClosingModal from "@/components/payroll/sickLeave/slr/SickLeaveRequestClosingModal.vue";

@Component({
	methods: {
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
	}
})
export default class TimesheetRequest extends Vue {

	private conf: ExistPolicies | null = null

	@Prop()
	private employment!: IEmployment

	private vcrs: Array<VCR> = []

	private runSL: SLR | null = null;

	public created() {
		this.getSLRs()
		this.existPTOAndSLP().then(() => this.getVcrs())
	}

	private existPTOAndSLP(): Promise<void> {
		return Promise.all(
			[
				PolicyService.getPTOPolicyByEmploymentId(this.employment.id!),
				PolicyService.getSickLeavePolicyByEmploymentId(this.employment.id!)
			]
		).then(
			res => {
				this.conf = {
					pto: !!res[0].data,
					slp: !!res[1].data
				}
			}
		)
	}

	private getVcrs() {
		VacationService.getAllVacationRequestsByFilter(
			new AllVCRFilter(
				{
					employmentsId: [this.employment.id!],
					status: VCRStatus.REQUESTED
				}
			)
		).then(
			res => {
				this.vcrs = res.data
			}
		)
	}

	private getSLRs() {
		SickLeaveService.getRequestPageByFilter(
			new SLRFilter(
				{
					status: SLRStatus.PENDING_SICK_LEAVE,
					employmentsId: [this.employment.id!]
				}
			)
		).then(
			res => {
				this.runSL = res.data.data[0]
			}
		)
	}

	private showVcrModal() {
		this.$modal.show(
			VacationRequestCreationModal,
			{
				employments: [this.employment],
				callback: () => this.getVcrs()
			}
		)
	}

	private showSLRModal() {
		this.$modal.show(
			SickLeaveNoticeModalCreation,
			{
				employment: this.employment,
				callback: () => {
					this.$emit("slr")
					this.getSLRs();
				}
			}
		)
	}

	private redirectToVcr(vcr: VCR) {
		this.$router.push(
			{
				name: RouteNames.PAYROLL_INDIVIDUAL_VACATION_REQUEST,
				params: {
					vcrId: vcr.id.toString(),
					personId: this.employment.details.id!.toString()
				}
			}
		)
	}

	resolveColor(vcr: VCR): string {
		switch (vcr.status) {
			case VCRStatus.APPROVED:
			case VCRStatus.REQUESTED:
			case VCRStatus.COMPLETED:return "#3ECB7F"
			case VCRStatus.IN_PROGRESS:return "#e1c611"
			case VCRStatus.REJECTED:return "#E14C44"
			case VCRStatus.CANCELLED: return "#818181"
		}
	}

	private showCloseSLRModal(hasSupportDoc: boolean) {
		this.$modal.show(
			SickLeaveRequestClosingModal,
			{
				employment: this.employment,
				slr: this.runSL,
				hasSupportDocument: hasSupportDoc,
				callback: () => {
					this.$emit("slr")
					this.runSL = null
				}
			}
		)
	}
}
