



















import {Vue, Component, Prop} from 'vue-property-decorator';
import Application from "@/state/Application";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import {CreateSLRPayload} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import moment from "moment/moment";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {processError} from "@/utils/ComponentUtils";

@Component({
	components: {PortalTextarea}
})
export default class SickLeaveNoticeModalCreation extends Vue {


	@Prop()
	private employment!: IEmployment

	@Prop()
	private callback!: () => void


	private req = new CreateSLRPayload(
		{
			employmentId: this.employment.id,
			startDate: moment().format("YYYY-MM-DD"),
		}
	)

	private startSickLeave() {
		Application.startLoading()
		SickLeaveService.createRequest(this.req).then(
			() => {
				Application.stopLoading();
				this.$modal.hideAll()
				this.callback();
			},
			err => processError(err, this)
		)
	}

}
