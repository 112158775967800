import CompanyDTO from "@/dto/company/CompanyDTO";
import {PolicySignature} from "@/dto/payroll/policies/PTOPolicyDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {ContractType} from "@/constants/EmploymentType";
import {Week} from "@/constants/DateTimeConstants";

export class Holiday {
    title: string | null = null
    formattedDate: string | null = null
    date: Date | null = null
}

export class HAOPolicy {
    readonly id!: number
    readonly title!: string
    readonly minPaidInterval!: number
    readonly minUnPaidInterval!: number
    readonly startWeek!: string
    readonly endWeek!: string
    readonly overtimeRate!: number
    readonly overtimeLimit!: number
    readonly techOvertimeLimit!: number
    readonly notificationPeriod!: number
    readonly regularWorkingDays!: Array<Holiday>
    readonly paidHolidays!: Array<Holiday>
    readonly unPaidHolidays!: Array<Holiday>
    readonly company!: CompanyDTO
    readonly contractType: ContractType | null = null
    readonly signatures!: Array<PolicySignature>
    readonly active!: boolean
    readonly createDate!: Date
}

export class CreateHAOPolicyPayload {
    title: string | null = "Holiday and Overtime Policy"
    minPaidInterval: number | null = 4
    minUnPaidInterval: number | null = null
    startWeek: string | null = Week.SUNDAY
    endWeek: string | null = Week.SATURDAY
    overtimeRate: number | null = 1.5
    overtimeLimit: number | null = 20
    techOvertimeLimit: number | null = 1
    notificationPeriod: number | null = 24
    regularWorkingDays: Array<Holiday> = []
    paidHolidays: Array<Holiday> = []
    unPaidHolidays: Array<Holiday> = []
    companyId: number | null = null
    contractType: ContractType | null = null

    constructor(init?: Partial<CreateHAOPolicyPayload>) {
        Object.assign(this, init)
    }
}

export class HAOPSignature {
    readonly id!: number
    readonly employment!: IEmployment
    readonly policy!: HAOPolicy
    readonly document: FileMetaDTO | null = null
    readonly publicId: string | null = null
}

export class HAOPFilter {
    companyId: number | null = null
    contractType: ContractType | null = null
    signed: boolean | null = null
    active: boolean | null = null
    page = 0
    size = 20

    constructor(init?: Partial<HAOPFilter>) {
        Object.assign(this, init)
    }
}
