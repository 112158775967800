export enum DocumentSigningStatus {
  CREATED = "CREATED",
  PARTIALLY_SIGNED = "PARTIALLY_SIGNED",
  SIGNED = "SIGNED",
}

export enum SigningStatus {
  ASSIGNED ="ASSIGNED",
  SENT = "SENT",
  OPENED = "OPENED",
  SIGNED = "SIGNED",
  DECLINED = "DECLINED",
}

export enum SignableDocumentType {
  FORM_W4_2023 = 'FORM_W4_2023',
  FORM_W9_2024 = 'FORM_W9_2024',
  FORM_W8BEN_2021 = 'FORM_W8BEN_2021',
  COMPANY_PTO_POLICY = 'COMPANY_PTO_POLICY',
  EMPLOYEE_PTO_POLICY = 'EMPLOYEE_PTO_POLICY',
  COMPANY_SICK_LEAVE_POLICY = 'COMPANY_SICK_LEAVE_POLICY',
  EMPLOYEE_SICK_LEAVE_POLICY = 'EMPLOYEE_SICK_LEAVE_POLICY',
  FORM_TR_2000_2021 = 'FORM_TR_2000_2021',
  FORM_8655_2024 = 'FORM_8655_2024',
  FORM_DOS_1556_F_2022 = 'FORM_DOS_1556_F_2022',
  TR_579_CT_2023 = 'TR_579_CT_2023',
  FORM_8879_C = 'FORM_8879_C',
  FORM_8879_C_2024_R2024 = 'FORM_8879_C_2024_R2024',
  NYC_579_COR_2023 = 'NYC_579_COR_2023',
  FORM_8879_CRP = 'FORM_8879_CRP',
  FORM_PA_8879_C_2022 = 'FORM_PA_8879_C_2022',
  FORM_PA_8879_P_2023 = 'FORM_PA_8879_P_2023',
  FORM_VA_8879_C_2023 = 'FORM_VA_8879_C_2023',
  FORM_8879_WH = 'FORM_8879_WH',
  NYC_579_UBTP_2023 = 'NYC_579_UBTP_2023',
  FORM_CBT_100_NJ = 'FORM_CBT_100_NJ',
  FORM_1100_DELAWARE_2023 = 'FORM_1100_DELAWARE_2023',
  FORM_IT_204_LL_2023 = 'FORM_IT_204_LL_2023',
  FORM_SC_8453_C_2021 = 'FORM_SC_8453_C_2021',
  FORM_CT_1065_1120_CT = 'FORM_CT_1065_1120_CT',
  F_3115_2022 = 'F_3115_2022',
  FORM_1120A_2024 = 'FORM_1120A_2024',
  FORM_CA_8453_C_2023 = 'FORM_CA_8453_C_2023',
  FORM_1359_2024_R2022 = 'FORM_1359_2024_R2022',
  // Unclassified person forms
  FORM_PA_8453_2019_R2019 = 'FORM_PA_8453_2019_R2019',
  FORM_PA_8453_2020_R2020 = 'FORM_PA_8453_2020_R2020',
  FORM_PA_8453_2021_R2021 = 'FORM_PA_8453_2021_R2021',
  FORM_PA_8453_2024_R2024 = 'FORM_PA_8453_2024_R2024',
  FORM_8879_2019_R2019 = 'FORM_8879_2019_R2019',
  FORM_8879_2020_R2021 = 'FORM_8879_2020_R2021',
  FORM_8879_2021_R2021 = 'FORM_8879_2021_R2021',
  FORM_8879_2024_R2021 = 'FORM_8879_2024_R2021',
  FORM_4506_T_R2024 = 'FORM_4506_T_R2024',
  FORM_2848_R2021 = 'FORM_2848_R2021',
  FORM_POA_1_R2017 = 'FORM_POA_1_R2017',
  FORM_TR_579_IT_R2022 = 'FORM_TR_579_IT_R2022',
  FORM_TR_579_IT_2024_R2024 = 'FORM_TR_579_IT_2024_R2024',
  FORM_8879_2022_R2021 = 'FORM_8879_2022_R2021',
  FORM_8453_GEORGIA_R2022 = 'FORM_8453_GEORGIA_R2022',
  FORM_8453_GEORGIA_R2024 = 'FORM_8453_GEORGIA_R2024',
  FORM_CA_8453_R2022 = 'FORM_CA_8453_R2022',
// FORM_2553 = 'FORM_2553',
  FORM_M_8453_MASSACHUSETTS_R2022 = 'FORM_M_8453_MASSACHUSETTS_R2022',
  FORM_VA_8879_VIRGINIA_R2022 = 'FORM_VA_8879_VIRGINIA_R2022',
  FORM_EL101_MARYLAND_R2022 = 'FORM_EL101_MARYLAND_R2022',
// FORM_IL_8453_ILLINOIS_2022 = 'FORM_IL_8453_ILLINOIS_2022',
  FORM_DR_8454_COLORADO_R2022 = 'FORM_DR_8454_COLORADO_R2022',
  FORM_DR_8454_COLORADO_2024_R2024 = 'FORM_DR_8454_COLORADO_2024_R2024',
  FORM_SC_8453_R2022 = 'FORM_SC_8453_R2022',
  FORM_AZ_8879_R2022 = 'FORM_AZ_8879_R2022',

}

export enum LabelType {
  SIGNATURE = "SIGNATURE",
  NAME = "NAME",
}

export enum SpouseSigningMode {
  IGNORED = "IGNORED", // ignored even if spouse is present
  OPTIONAL = "OPTIONAL", // requested if spouse is present
  REQUIRED = "REQUIRED" // raises error if spouse is absent
}