






import {Vue, Component, Prop} from 'vue-property-decorator';
import {TimesheetSickLeaveRecord} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import moment from "moment/moment";

@Component({
	components: {PortalCheckbox}
})
export default class SLRDayPayFlagPrepare extends Vue {

	@Prop()
	private day!: TimesheetSickLeaveRecord

	private resolveTitle(): string {
		return `${moment().localeData().months()[moment(this.day.date).month()]} ${moment(this.day.date).format('YYYY-MM-DD')} is a paid day?`;
	}

}
