














import {Vue, Component} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import Application from "@/state/Application";
import EmploymentService from "@/services/EmploymentService";
import {processError} from "@/utils/ComponentUtils";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import SLPView from "@/components/payroll/policies/sickLeave/SLPView.vue";
import HAOPView from "@/components/payroll/policies/haop/HAOPView.vue";

@Component({
	components: {HAOPView, SLPView, BTPortalCollapse}
})
export default class IndividualHAOP extends Vue {
	private employments: Array<IEmployment> = [];

	private filter = new EmploymentFilter(
		{
			detailsId: this.$wss.getCurrent.id,
			status: [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS]
		}
	)

	private loadEmployments() {
		Application.startLoading();
		EmploymentService.getPageByFilter<IEmployment>(this.filter).then(
			res => {
				this.employments = res.data.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created(): void {
		this.loadEmployments()
	}
}
