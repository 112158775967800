import {IEmployment} from "@/dto/payroll/IEmployment";
import moment, {Moment} from "moment/moment";

export type TimesheetType = "REGULAR" | "OVERTIME" | "VACATION" | "SICK_LEAVE" | "LWOP" | "USL" | "PENDING_SICK_LEAVE" | "PROCESSING_SICK_LEAVE"
export type InsertMode = "MANUAL" | "AUTO"

export class TimeSheetRecord{
    id: number | null = null;
    date!: Date;
    workPeriodStart!: Date;
    workPeriodEnd: Date | null = null;
    type!: TimesheetType;
    mode!: InsertMode
    readonly duration: string = this.workPeriodStart != undefined ? "0 <" : "-"

    constructor(date: Date, type: TimesheetType, workPeriodStart: Date) {
        this.date = date;
        this.type = type;
        this.workPeriodStart = workPeriodStart
    }
}

export class TimeSheetPayDay {
    date!: Date;
    records: Array<TimeSheetRecord> = []
    readonly duration: string = "-"
    readonly durationInSeconds!: number
    readonly isVacation = false
    readonly isSickLeave = false
    readonly isLWOP = false
    readonly isUSL = false
    readonly isPendingSickLeave = false
    readonly isProcessingSickLeave = false
    readonly isClosed = true

    constructor(date: Date, records: Array<TimeSheetRecord>) {
        this.date = date;
        this.records = records;
    }

}

export class TimeSheetPayPeriod {
    employment!: IEmployment;
    payDates: Array<TimeSheetPayDay> = [];
    openRecord?: TimeSheetRecord
    readonly duration: string = "0:0"
}

export class UpdateTimeSheetRecordPayload {
    workPeriodStart: string | null = null
    workPeriodEnd: string | null = null
    id: number | null = null
    employmentId: number | null = null;
    mode: InsertMode | null = null

    static builder() {
        return new UpdateTimeSheetRecordPayloadBuilder();
    }
}

export class CreateTimeSheetRecordPayload {
    workPeriodStart: string | null = null
    workPeriodEnd: string | null = null
    date: Date | null = null
    employmentId: number | null = null;
    mode: InsertMode | null = null
    projectId: number | null = null

    static builder() {
        return new CreateTimeSheetRecordPayloadBuilder()
    }

}

class UpdateTimeSheetRecordPayloadBuilder {
    workPeriodStartInternal: string | null = null
    workPeriodEndInternal: string | null = null
    idInternal: number | null = null
    employmentIdInternal: number | null = null;
    modeInternal: InsertMode | null = null

    public workPeriodStart(workPeriodStart: Date): UpdateTimeSheetRecordPayloadBuilder {
        this.workPeriodStartInternal = moment(workPeriodStart).format("YYYY-MM-DD[T]HH:mm")
        return this
    }

    public mode(mode: InsertMode): UpdateTimeSheetRecordPayloadBuilder {
        this.modeInternal = mode;
        return this
    }

    public workPeriodEnd(workPeriodEnd: Date | null): UpdateTimeSheetRecordPayloadBuilder {
        if (workPeriodEnd) {
            this.workPeriodEndInternal = moment(workPeriodEnd).format("YYYY-MM-DD[T]HH:mm")
        }
        return this
    }

    public id(id: number | null): UpdateTimeSheetRecordPayloadBuilder {
        this.idInternal = id
        return this
    }

    public employmentId(employmentId: number | null): UpdateTimeSheetRecordPayloadBuilder {
        this.employmentIdInternal = employmentId;
        return this
    }

    public build(): UpdateTimeSheetRecordPayload {
        const req = new UpdateTimeSheetRecordPayload()
        req.workPeriodStart = this.workPeriodStartInternal;
        req.workPeriodEnd = this.workPeriodEndInternal;
        req.id = this.idInternal;
        req.mode = this.modeInternal;
        req.employmentId = this.employmentIdInternal;
        return req
    }
}

export class CreateTimeSheetRecordPayloadBuilder {
    workPeriodStartInternal: string | null = null
    workPeriodEndInternal: string | null = null
    dateInternal: Date | null = null
    employmentIdInternal: number | null = null;
    modeInternal: InsertMode | null = null
    projectIdInternal: number | null = null

    public workPeriodStart(workPeriodStart: Date): CreateTimeSheetRecordPayloadBuilder {
        this.workPeriodStartInternal = moment(workPeriodStart).format("YYYY-MM-DD[T]HH:mm")
        return this
    }

    public mode(mode: InsertMode): CreateTimeSheetRecordPayloadBuilder {
        this.modeInternal = mode;
        return this
    }

    public projectId(projectId: number | null): CreateTimeSheetRecordPayloadBuilder {
        this.projectIdInternal = projectId;
        return this
    }

    public workPeriodEnd(workPeriodEnd: Date | null): CreateTimeSheetRecordPayloadBuilder {
        if (workPeriodEnd) {
            this.workPeriodEndInternal = moment(workPeriodEnd).format("YYYY-MM-DD[T]HH:mm")
        }
        return this
    }

    public date(date: Date): CreateTimeSheetRecordPayloadBuilder {
        this.dateInternal = date
        return this
    }

    public employmentId(employmentId: number | null): CreateTimeSheetRecordPayloadBuilder {
        this.employmentIdInternal = employmentId;
        return this
    }

    public build(): CreateTimeSheetRecordPayload {
        const req = new CreateTimeSheetRecordPayload()
        req.workPeriodStart = this.workPeriodStartInternal;
        req.workPeriodEnd = this.workPeriodEndInternal;
        req.date = this.dateInternal;
        req.employmentId = this.employmentIdInternal;
        req.mode = this.modeInternal;
        req.projectId = this.projectIdInternal
        return req
    }
}

export class TimeSheetFilter {
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    date: Date | null = null;
    employmentsId: Array<number> = [];
    projectId: number | null = null

    constructor(init?: Partial<TimeSheetFilter>) {
        Object.assign(this, init)
    }
}
