
























import {Vue, Component} from 'vue-property-decorator';
import {VCR} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {processError} from "@/utils/ComponentUtils";
import VacationRequest from "@/components/payroll/vacation/vcr/VacationRequest.vue";
import BusinessVCR from "@/components/payroll/vacation/vcr/BusinessVCR.vue";
import SickLeaveRequest from "@/components/payroll/sickLeave/slr/SickLeaveRequest.vue";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {SLR} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import SLRDecision from "@/components/payroll/sickLeave/slr/SLRDecision.vue";

type SLRMode = "VIEW" | "DECISION"

@Component({
	components: {SLRDecision, SickLeaveRequest}
})
export default class SickLeaveRequestByQueryId extends Vue {

	private slr: SLR | null = null;

	private mode: SLRMode | null = null

	public created() {
		this.loadByQueryId()
	}

	private loadByQueryId() {
		Application.startLoading();
		SickLeaveService.getSLRById(this.$route.params.slrId).then(
			res => {
				this.slr = res.data
				if (res.data.days) {
					this.mode = "DECISION"
				} else {
					this.mode = "VIEW"
				}
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}


}
