




































































































































import {Component, Vue} from 'vue-property-decorator';
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {prettyEnum} from "@/utils/StringUtils";
import moment from "moment";
import {Moment} from "moment-timezone";
import {HAOPFilter, HAOPolicy} from "@/dto/payroll/policies/HAOPolicyDTO";
import {ContractType} from "@/constants/EmploymentType";
import {PolicySignature} from "@/dto/payroll/policies/PTOPolicyDTO";
import PolicySignatureOptions from "@/components/payroll/policies/PolicySignatureOptions.vue";
import Application from "@/state/Application";
import PolicyService from "@/services/payroll/PolicyService";
import {processError} from "@/utils/ComponentUtils";
import HAOPCreationModal from "@/components/payroll/policies/haop/HAOPCreationModal.vue";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";

@Component({
	computed: {
		ContractType() {
			return ContractType
		}
	},
	components: {SingleFileHolder, BTPortalCollapse},
	methods: {
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	}
})
export default class HAOPolicyList extends Vue {

	private haopList: Array<HAOPolicy> = []

	private filter = new HAOPFilter(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	created() {
		this.getHAOPs()
	}

	private getHAOPs() {
		Application.startLoading()
		PolicyService.getHAOPs(this.filter).then(
			(res) => {
				this.haopList = res.data.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private showOptions(signature: PolicySignature) {
		this.$modal.show(
			PolicySignatureOptions,
			{
				signature: signature,
				callback: this.getHAOPs,
				mode: "HAOP"
			}
		)
	}

	private create() {
		this.$modal.show(
			HAOPCreationModal,
			{
				callback: this.getHAOPs,
				mode: "HAOP"
			})
	}

	private disablePolicy(haopId: number) {
		Application.startLoading()
		PolicyService.disableHAOP(haopId).then(
			() => {
				Application.stopLoading()
				this.getHAOPs()
			},
			err => processError(err, this)
		)
	}

	private disable(haop: HAOPolicy) {
		this.$modal.show(
			DeleteConfirmation,
			{
				onDelete: () => this.disablePolicy(haop.id),
				targetName: haop.title,
				operationName: "disable"
			}
		)
	}

}
