
































































































import {Vue, Component, Prop} from 'vue-property-decorator';
import {
	CreateTimeSheetRecordPayload,
	TimeSheetPayDay,
	UpdateTimeSheetRecordPayload
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import moment, {Moment} from "moment-timezone";
import Application from "@/state/Application";
import TimesheetService from "@/services/payroll/TimesheetService";
import {processError} from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";

@Component(
	{
		methods: {
			moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
				return moment(inp, format, language, strict)
			},
		}
	}
)
export default class TimeSheetDay extends Vue {

	private timerTrigger = 0

	@Prop()
	private day!: TimeSheetPayDay
	@Prop({default: null, required: false})
	private project!: number | null
	@Prop()
	private isSelected!: boolean
	@Prop()
	private employment!: IEmployment

	get isCurrentDay(): boolean { return moment(this.day.date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD") }

	private autoCreateRecord(): void {
		Application.startLoading()
		TimesheetService.createRecord(
			CreateTimeSheetRecordPayload.builder()
				.workPeriodStart(new Date())
				.date(this.day.date)
				.employmentId(this.employment.id)
				.projectId(this.project)
				.mode("AUTO")
				.build()
		).then(
			() => {
				Application.stopLoading()
				this.$emit("onCreateRecord")
			},
			err => processError(err, this)
		)
	}

	public mounted(): void {
		setInterval(
			() => {
				if (!this.day.isClosed) {
					this.timerTrigger += 1
				}
			},
			1000)
	}

	private processClick(): void {
		if (this.day.isSickLeave || this.day.isVacation || this.day.isLWOP || this.day.isProcessingSickLeave || this.day.isPendingSickLeave || this.day.isUSL || this.day.isSickLeave) return;
		if (this.day.isClosed) {
			if (this.isCurrentDay) {
				this.autoCreateRecord()
			}
		} else {
			this.stopRecord()
		}
	}

	private stopRecord(): void {
		Application.startLoading()
		const recordForClose = this.day.records.find(it => it.workPeriodEnd == null)
		TimesheetService.updateRecord(
			UpdateTimeSheetRecordPayload.builder()
				.id(recordForClose!.id)
				.employmentId(this.employment.id)
				.workPeriodEnd(new Date())
				.mode("AUTO")
				.workPeriodStart(recordForClose!.workPeriodStart)
				.build()
		).then(
			() => {
				Application.stopLoading()
				this.$emit("onUpdateRecord")
			},
			err => processError(err, this)
		)
	}

	private timer(): string {
		if (!this.day.isClosed) {
			let currentWorkDuration = this.day.durationInSeconds + 1;
			currentWorkDuration += Math.floor((new Date().getTime() - moment(this.day.records[this.day.records.length - 1].workPeriodStart).toDate().getTime()) / 1000)
			return `${Math.floor(currentWorkDuration / 3600)}h ${Math.floor(currentWorkDuration / 60 % 60)}m ${currentWorkDuration % 60}s`
		} else {
			return this.day.duration
		}
	}
}

