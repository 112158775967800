import QueryUtils from "@/utils/QueryUtils";
import axios, {AxiosResponse} from "axios";
import Pageable from "@/dto/Pageable";
import {PAYROLL_SICK_ENDPOINT, PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import {
    CreateSickLeavePayload,
    SickLeave,
    SickLeaveBalance, SickLeaveBalanceDetails, SickLeaveBalanceDetailsForPeriod,
    SickLeaveFilter, UpdateSickLeaveBalancePayload
} from "@/components/payroll/sickLeave/SickLeave";
import {
    ChangeStatusPayload, CloseSLRPayload, CompleteSLRPayload, CreateCommentPayload,
    CreateSLRPayload,
    DecisionSLR,
    SLR,
    SLRFilter
} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import {BalanceDetailsFilterForPeriod} from "@/dto/payroll/VacationDTO";

class SickLeaveService {
    getPageByFilter(filter: SickLeaveFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<SickLeave>>(`${PAYROLL_SICK_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getSickLeaveBalance(employmentId: number): Promise<AxiosResponse<SickLeaveBalance>> {
        return axios.get<SickLeaveBalance>(`${PAYROLL_SICK_ENDPOINT}/balance?employmentId=${employmentId}`, {headers: authHeader()})
    }

    createSickLeaveRecord(req: CreateSickLeavePayload) {
        return axios.post<void>(`${PAYROLL_SICK_ENDPOINT}`, req, {headers: authHeader()})
    }

    deleteSickLeaveRecord(sickLeaveId: number, employmentId: number) {
        return axios.delete(`${PAYROLL_SICK_ENDPOINT}?sickLeaveId=${sickLeaveId}&employmentId=${employmentId}`, {headers: authHeader()})
    }

    updateSickLeaveBalance(payload: UpdateSickLeaveBalancePayload) {
        return axios.put(`${PAYROLL_SICK_ENDPOINT}/balance`, payload, {headers: authHeader()})
    }

    getRequestPageByFilter(filter: SLRFilter): Promise<AxiosResponse<Pageable<SLR>>> {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<SLR>>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getSLRById(id: number | string): Promise<AxiosResponse<DecisionSLR>> {
        return axios.get<DecisionSLR>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}/${id}`, {headers: authHeader()})
    }

    createRequest(payload: CreateSLRPayload) {
        return axios.post<void>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}`, payload, {headers: authHeader()})
    }

    approveRequest(payload: ChangeStatusPayload) {
        return axios.put<void>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}/approve`, payload, {headers: authHeader()})
    }

    rejectRequest(payload: ChangeStatusPayload) {
        return axios.put<void>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}/reject`, payload, {headers: authHeader()})
    }

    cancelRequest(payload: ChangeStatusPayload) {
        return axios.put<void>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}/cancel`, payload, {headers: authHeader()})
    }

    createComment(payload: CreateCommentPayload) {
        return axios.post<void>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}/comment`, payload, {headers: authHeader()})
    }

    getBalanceDetailsForPeriod(filter: BalanceDetailsFilterForPeriod): Promise<AxiosResponse<SickLeaveBalanceDetailsForPeriod>> {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<SickLeaveBalanceDetailsForPeriod>(`${PAYROLL_SICK_ENDPOINT}/balance/period_details${query}`, {headers: authHeader()})
    }

    getBalanceDetails(balanceDetailsFilter: BalanceDetailsFilterForPeriod): Promise<AxiosResponse<Pageable<SickLeaveBalanceDetails>>> {
        const query = QueryUtils.objectToQueryString(balanceDetailsFilter)
        return axios.get<Pageable<SickLeaveBalanceDetails>>(`${PAYROLL_SICK_ENDPOINT}/balance/details${query}`, {headers: authHeader()})
    }

    closeRequest(req: CloseSLRPayload): Promise<AxiosResponse<void>> {
        return axios.put<void>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}/close`, req, {headers: authHeader()})
    }

    completeSLR(req: CompleteSLRPayload): Promise<AxiosResponse<void>> {
        return axios.put<void>(`${PAYROLL_SICK_LEAVE_REQUEST_ENDPOINT}/complete`, req, {headers: authHeader()})
    }
}

export default new SickLeaveService()