import {IEmployment} from "@/dto/payroll/IEmployment";
import {SickLeave, SickLeaveBalance} from "@/components/payroll/sickLeave/SickLeave";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export enum SLRStatus {
    REQUESTED = "REQUESTED",
    COMPLETED = "COMPLETED",
    PENDING_SICK_LEAVE = "PENDING_SICK_LEAVE",
    PROCESSING_SICK_LEAVE = "PROCESSING_SICK_LEAVE",
    CANCELLED = "CANCELLED"
}

export class ChangeStatusPayload {
    requestId: number | null = null;
    employmentId: number | null = null;
    comment: string | null = null;

    constructor(init?: Partial<ChangeStatusPayload>) {
        Object.assign(this, init);
    }
}

export class CreateCommentPayload extends ChangeStatusPayload {
    constructor(init?: Partial<CreateCommentPayload>) {
        super(init)
    }
}

export class CreateSLRPayload {
    startDate: Date | null | string = null;
    employmentId: number | null = null;
    comment: string | null = null;

    constructor(init?: Partial<CreateSLRPayload>) {
        Object.assign(this, init)
    }
}

export class SLRFilter {
    employerId: number | null = null;
    employmentsId: Array<number> | null = null;
    page = 0
    size = 20;
    status: SLRStatus | null = null
    startDate: Date | null = null;
    endDate: Date | null = null;

    constructor(init?: Partial<SLRFilter>) {
        Object.assign(this, init)
    }
}

export class SLRComment {
    content!: string
    sendBy!: string
    requester!: boolean
    createAt!: Date
}

export class SLR {
    id!: number;
    status!: SLRStatus;
    requester!: IEmployment
    comments!: Array<SLRComment>
    sickLeave!: SickLeave
    assignee!: Account
    duration!: string
    paid!: boolean
    hospitalReport: FileMetaDTO | null = null
}

export class DecisionSLR extends SLR {
    sickLeaveBalance!: SickLeaveBalance
    enoughBalance!: boolean
    days: Array<TimesheetSickLeaveRecord> = []
}

export class TimesheetSickLeaveRecord {
    date!: Date;
    workPeriodStart!: Date;
    workPeriodEnd!: Date;
    paid!: Date
}

export class CloseSLRPayload {
    endDate: Date | string | null = null;
    employmentId: number | null = null;
    requestId: number | null = null;
    paid = false;
    hospitalReport: FileMetaDTO | null = null;

    constructor(init?: Partial<CloseSLRPayload>) {
        Object.assign(this, init)
    }
}

export class CompleteSLRPayload {
    requestId: number | null = null;
    markedPeriod: Array<TimesheetSickLeaveRecord> = [];
    companyId: number | null = null;

    constructor(init?: Partial<CompleteSLRPayload>) {
        Object.assign(this, init)
    }
}
