var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
			timesheet_day: true,
			timesheet_day_selected: _vm.isSelected,
			timesheet_day_vacation: _vm.day.isVacation || _vm.day.isLWOP,
			timesheet_day_sick_leave: _vm.day.isSickLeave || _vm.day.isUSL || _vm.day.isProcessingSickLeave || _vm.day.isPendingSickLeave
		},on:{"click":function($event){return _vm.$emit('selectDay', _vm.day)}}},[_c('div',{staticClass:"timesheet_day__title",style:({
			paddingTop: _vm.day.isVacation || _vm.day.isSickLeave || _vm.day.isUSL || _vm.day.isLWOP || _vm.day.isProcessingSickLeave || _vm.day.isPendingSickLeave ? '2px' : '12px'
		})},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment().localeData().weekdaysShort()[_vm.moment(this.day.date).weekday()]))]),_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment(_vm.day.date).format("D")))])]),_c('div',{staticClass:"timesheet_day__body position-relative",style:({
			paddingTop: _vm.day.isVacation || _vm.day.isSickLeave || _vm.day.isUSL || _vm.day.isLWOP || _vm.day.isProcessingSickLeave || _vm.day.isPendingSickLeave ? '2px' : '15px',
			paddingBottom: _vm.day.isVacation || _vm.day.isSickLeave || _vm.day.isUSL || _vm.day.isLWOP || _vm.day.isProcessingSickLeave || _vm.day.isPendingSickLeave ? '7px' : '8px'
		})},[(_vm.day.isVacation)?_c('div',{staticClass:"text-center"},[_vm._v(" Vacation ")]):_vm._e(),(_vm.day.isLWOP)?_c('div',{staticClass:"text-center"},[_vm._v(" Leave Without Pay ")]):_vm._e(),(_vm.day.isSickLeave)?_c('div',{staticClass:"text-center"},[_vm._v(" Sick Leave ")]):_vm._e(),(_vm.day.isUSL)?_c('div',{staticClass:"text-center"},[_vm._v(" Unpaid Sick Leave ")]):_vm._e(),(_vm.day.isProcessingSickLeave)?_c('div',{staticClass:"text-center"},[_vm._v(" Sick in processing ")]):_vm._e(),(_vm.day.isPendingSickLeave)?_c('div',{staticClass:"text-center"},[_vm._v(" Pending Sick Leave ")]):_vm._e(),(!_vm.day.isLWOP && !_vm.day.isUSL && !_vm.day.isProcessingSickLeave && !_vm.day.isPendingSickLeave)?_c('div',{staticClass:"text-center"},[(_vm.day.isClosed)?_c('span',[_vm._v(_vm._s(_vm.day.duration))]):_c('font-awesome-icon',{attrs:{"icon":"clock","size":"lg"}})],1):_vm._e()])]),_c('div',{staticClass:"timesheet_day_mobile",class:{
		 timer: !(_vm.day.isVacation || _vm.day.isSickLeave || _vm.day.isUSL || _vm.day.isLWOP || _vm.day.isProcessingSickLeave || _vm.day.isPendingSickLeave) && !_vm.day.isClosed,
		 start: !(_vm.day.isVacation || _vm.day.isSickLeave || _vm.day.isUSL || _vm.day.isLWOP || _vm.day.isProcessingSickLeave || _vm.day.isPendingSickLeave) && _vm.day.isClosed},on:{"click":_vm.processClick}},[_c('div',[(_vm.day.isVacation)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Vacation ")]):(_vm.day.isSickLeave)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Sick Leave ")]):(_vm.day.isLWOP)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Leave Without Pay ")]):(_vm.day.isUSL)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Unpaid Sick Leave ")]):(_vm.day.isProcessingSickLeave)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Sick Leave in processing ")]):(_vm.day.isPendingSickLeave)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Pending Sick Leave ")]):(_vm.day.isClosed)?_c('div',[_c('div',{key:_vm.timerTrigger,staticClass:"timesheet_day_mobile__timer"},[_vm._v(" "+_vm._s(_vm.timer())+" ")]),(_vm.isCurrentDay)?_c('div',{staticClass:"timesheet_day_mobile_timer__description"},[_vm._v(" Start time ")]):_vm._e()]):_c('div',[_c('div',{key:_vm.timerTrigger,staticClass:"timesheet_day_mobile__timer"},[_vm._v(" "+_vm._s(_vm.timer())+" ")]),(!_vm.day.isClosed)?_c('div',{staticClass:"timesheet_day_mobile_timer__description"},[_vm._v(" Stop timer ")]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }