import { render, staticRenderFns } from "./HAOPolicyList.vue?vue&type=template&id=6bec013a&scoped=true&"
import script from "./HAOPolicyList.vue?vue&type=script&lang=ts&"
export * from "./HAOPolicyList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bec013a",
  null
  
)

export default component.exports