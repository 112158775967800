




















































































































import PortalInput from "@/components/common/PortalInput.vue";
import {CreatePTOPolicyPayload} from "@/dto/payroll/policies/PTOPolicyDTO";
import {Component, Prop, Vue} from "vue-property-decorator";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import Portal from "portal-vue/src/components/portal";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {EMPLOYMENT_TYPE_OPTIONS, ContractType, contractTypeOptions, EmploymentType} from "@/constants/EmploymentType";
import {STATE_LIST} from "@/constants/ProfileConstants";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PolicyService from "@/services/payroll/PolicyService";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";

@Component(
	{
		methods: {
			contractTypeOptions: contractTypeOptions,
			STATE_LIST() {
				return STATE_LIST
			}
		},
		computed: {
			ContractType() {
				return ContractType
			},
			SelectOption() {
				return SelectOption
			},
			EMPLOYMENT_TYPE_OPTIONS() {
				return EMPLOYMENT_TYPE_OPTIONS
			}
		},
		components: {PortalRadio, PortalSelect, Portal, PortalCheckbox, PortalInput}
	}
)
export default class PTOCreationModal extends Vue {

	@Prop()
	private callback!: () => void

	private states = STATE_LIST;

	private req: CreatePTOPolicyPayload = new CreatePTOPolicyPayload(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	private create() {
		ifValid(this, () => {
				Application.startLoading();
				PolicyService.createPTOPolicy(this.req).then(
					() => {
						Application.stopLoading()
						this.$modal.hideAll();
						this.callback()
					},
					err => processError(err, this)
				)
			}
		)
	}

	private selectState() {
		this.req.title = `PTO (Paid Time Off) Policy (${this.req.applicableState})`
	}

	private get contractType(): Array<SelectOption> {
		const options: Array<SelectOption> = []
		options.push(SelectOption.builder().title("All").value(null).build())
		options.push(...contractTypeOptions([ContractType.FIXED_TERM]))
		return options;
	}

	private get employmentOptions(): SelectOption[] {
		return [
			SelectOption.builder().title("All").value(null).build(),
			SelectOption.builder().title("Employee").value(EmploymentType.STAFF).build(),
			SelectOption.builder().title("Foreign contractor").value(EmploymentType.FOREIGN).build()
		]
	}

}
