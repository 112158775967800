





















import {Vue, Component, Prop} from 'vue-property-decorator';
import Application from "@/state/Application";
import PolicyService from "@/services/payroll/PolicyService";
import {processError} from "@/utils/ComponentUtils";
import SickLeaveAttachModal from "@/components/payroll/policies/sickLeave/SickLeaveAttachModal.vue";
import RouteNames from "@/router/RouteNames";
import {HAOPSignature} from "@/dto/payroll/policies/HAOPolicyDTO";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";

@Component({
	components: {EmbeddedFile}
})
export default class HAOPView extends Vue {
	@Prop()
	private employmentId!: number | null

	private signature: HAOPSignature | null = null;

	private loadHAOPByEmploymentId() {
		Application.startLoading()
		PolicyService.getHAOPByEmploymentId(this.employmentId!).then(
			res => {
				this.signature = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadHAOPByEmploymentId()
	}

	private openAttachModal() {
		this.$modal.show(
			SickLeaveAttachModal,
			{
				employmentId: this.employmentId,
				callback: () => this.loadHAOPByEmploymentId()
			}
		)
	}

	private toSignPage() {
		this.$router.push({name: RouteNames.SIGN_PUBLIC, params: {publicId: this.signature!.publicId!}})
	}
}
