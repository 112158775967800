



















import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import {v4 as uuidv4} from "uuid";

@Component<PortalDate>({
  inheritAttrs: false,
})
export default class PortalDate extends Vue {
	@VModel({type: String})
	private inputValue!: string;

	@Prop({default: "date"})
	private type!: "date" | "datetime-local" | "week" | "time";

	@Prop({default: null})
	private name!: string | null;

	@Prop({default: function () {return `${uuidv4()}`}})
	private id!: string | null;

	private idInternal: string | null = this.id;

	@Prop()
	private label!: string;

	@Prop({default: null})
	private max!: string | null;

	@Prop({default: null})
	private min!: string | null;

	@Prop({default: null})
	private hint!: string | null;

	@Prop({default: ''})
	private validatePattern!: string;

	@Prop({default: null})
	private change!: (val: string) => void | null

	public created(): void {
		if (this.name == null && this.idInternal == null) {
			this.idInternal = uuidv4()
		}
    this.$validator = this.$parent.$validator;
	}

	get isRequired(): boolean {
		return this.validatePattern?.includes("required");
	}
}
